<template>
  <div>
    <container-form :showLeftIcon="false">
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start align-items-start">
            <div class="p-1 border-warning bg-light-warning rounded-lg mr-2">
              <feather-icon icon="CheckSquareIcon" size="24" class="text-dark" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="mb-50">2. Permissão de acesso ao seu SCR</h4>
              <h5 class="mb-0 mt-50 text-muted">
                Responda um breve questionário para conceder o acesso.
              </h5>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <b-button variant="outline-primary" @click="showCancelSwal"> Cancelar </b-button>
            <b-button variant="primary" class="ml-2" @click="getQuestions">
              Permitir acesso
            </b-button>
          </div>
        </div>
      </template>

      <template #form>
        <div class="d-flex">
          <b-img src="@/assets/images/analise_de_credito_icon.svg" height="112" class="mt-2" />
          <div class="mt-2">
            <p>Estamos quase concluindo.</p>
            <span>
              Para concluir esse processo, precisamos ter acesso ao seu SCR - Sistema de Informações
              de Crédito. Destacamos que esse é um procedimento simples e seguro. Visamos apenas
              reunir informações fundamentais para garantir um bom negócio entre nós.
            </span>
          </div>
        </div>
      </template>
    </container-form>
    <b-modal
      id="scr-questions"
      centered
      hide-footer
      header-bg-variant="white"
      :size="modalSize"
      v-if="questions.length"
    >
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        v-if="!lastQuestion"
      >
        <h3 class="mt-1 text-primary text-center">{{ questions[currentQuestion].pergunta }}</h3>
        <div class="mt-2">
          <b-button variant="outline-primary" @click="showCancelModal" class="mr-2">Não</b-button>
          <b-button variant="primary" @click="answerQuestion">Sim</b-button>
        </div>
        <b-progress :value="currentProgress" max="100" class="mb-3 mt-3 w-50" />
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center" v-else>
        <h3 class="mt-2 text-primary text-center">Você autoriza a consultar ao BACEN?</h3>
        <span class="text-center mt-1"
          >Para disponibilizar ofertas mais precisas e justas, precisamos ter acesso aos dados da
          sua empresa no BACEN, Sistema de Informações de Créditos do Banco Central. Fique
          tranquilo, nós contamos com tecnologia inovadora e segurança criptografada e todos os seus
          dados estão seguros.</span
        >
        <b-form-checkbox v-model="terms" class="custom-control-primary mt-1"
          >Declaro que concordo com o
          <a
            href="https://policies-creditcorp-public.s3.sa-east-1.amazonaws.com/pamcash/Condi%C3%A7%C3%B5es+Gerais+Creditcorp+para+Permiss%C3%A3o+de+Acessoao+Sistema+de+Informa%C3%A7%C3%B5es+de+Cr%C3%A9ditos+(SCR).pdf"
            target="_blank"
            >Termo de Uso</a
          >
          da CreditCorp.</b-form-checkbox
        >
        <div class="mt-3">
          <b-button variant="outline-primary" @click="showCancelModal" class="mr-2">Não</b-button>
          <b-button variant="primary" @click="sendConsent" :disabled="!terms">Sim</b-button>
        </div>
        <b-progress :value="currentProgress" max="100" class="mb-4 mt-3 w-50" height="8px" />
      </div>
    </b-modal>
    <b-modal id="scr-help" centered size="lg" header-bg-variant="white">
      <b-row>
        <b-col cols="12">
          <h4>O que é o SCR?</h4>
          <div>
            <p>
              O sistema de informações de Crédito do Banco Central do Brasil (SCR) é um banco de
              dados com informações sobre todas as operações de crédito acima de R$200,00, como
              empréstimos, financiamentos, avais e fianças, realizadas entre as instituições
              financeiras ou fintechs e seus clientes.
            </p>
            <p>
              O SCR é diferente do SPC (Serviço de Proteção ao Crédito) ou SERASA, pois é um sistema
              próprio do Banco Central que não indica apenas os negativos, mas todas as transações
              financeiras que são realizadas no país.
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <h4>Qual a finalidade do SCR?</h4>
          <div>
            <p>
              Permite que os clientes das instituições financeiras ou fintechs o acompanhem as
              informações de suas operações de crédito que estão registradas no SCR, trazendo maior
              transparência. Sem desrespeitar o sigilo bancário.
            </p>

            <p>
              Permite que o Banco Central do Brasil obtenha informações mais precisas para o
              controle das operações de crédito praticadas pelas instituições financeiras ou
              fintechs autorizadas, oferecendo maior credibilidade e segurança ao público que
              consome os serviços e produtos destas empresas.
            </p>

            <p>
              Permite que as instituições financeiras ou fintechs façam uma melhor avaliação da
              capacidade de pagamento de seus clientes, garantindo que as aprovações sejam feitas
              com mais segurança e precisão. Com isso, também conseguem praticar taxas de juros
              adequadas ao perfil de crédito dos seus clientes através de uma análise
              individualizada.
            </p>
          </div>
        </b-col>
      </b-row>
      <template #modal-footer="{ close }">
        <b-row align-h="start" align-v="center" class="w-100 p-0 pb-2">
          <b-col cols="12" class="">
            <b-button variant="outline-primary" class="text-left" @click="close()">Fechar</b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ContainerForm from '@/views/client/dashboard/components/manual_offer/ContainerForm.vue'
import CcDropzone from '@/@core/components/cc-dropzone/CcDropzone.vue'
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BModal,
  BProgress,
  BFormCheckbox,
  BLink,
  VBModal,
} from 'bootstrap-vue'

export default {
  name: 'ScrPermission',
  props: {
    offerKind: {
      type: String,
      default: 'manual',
    },
  },
  components: {
    ContainerForm,
    CcDropzone,
    BImg,
    BRow,
    BCol,
    BButton,
    BModal,
    BProgress,
    BFormCheckbox,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      terms: false,
      lastQuestion: false,
      currentProgress: 0,
      progress: 0,
      currentQuestion: 0,
      questions: [],
    }
  },
  async mounted() {
    await this.getQuestions()
    this.progress = 100 / (this.questions.length + 1)
  },
  methods: {
    async getQuestions() {
      try {
        this.$swal.showLoading()
        const { data } = await this.$store.dispatch('dashboard/getQuestions')
        this.questions = data
        this.$swal.close()
        this.$bvModal.show('scr-questions')
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Erro ao tentar enviar os SPEDs, por favor selecione os arquivos e tente novamente!',
        })
      }
    },
    answerQuestion() {
      this.currentProgress += this.progress
      this.currentQuestion += 1

      if (this.currentQuestion === this.questions.length) this.lastQuestion = true
    },
    showCancelModal() {
      this.$bvModal.hide('scr-questions')
      this.cancelQuestions()
    },
    cancelQuestions() {
      const text =
        this.questions[this.currentQuestion]?.mensagem_alerta_negativa ||
        'Essa etapa é necessária para dar continuidade na contratação. Você tem certeza que deseja cancelar esta autorização?'
      const inLastQuestion = this.currentQuestion >= this.questions.length - 1
      this.$swal
        .fire({
          title: inLastQuestion && 'Cancelar autorização',
          text: text,
          reverseButtons: true,
          showCancelButton: inLastQuestion,
          confirmButtonText: inLastQuestion ? 'Continuar' : 'Fechar',
          cancelButtonText: 'Cancelar',
        })
        .then(() => {
          if (!inLastQuestion) this.resetQuestions()
        })
    },
    showCancelSwal() {
      this.$swal
        .fire({
          text: 'Essa etapa é necessária para dar continuidade na contratação. Você tem certeza que deseja cancelar esta operação?',
          reverseButtons: true,
          confirmButtonColor: '#093272',
          showCancelButton: true,
          confirmButtonText: 'Sim, cancelar',
          cancelButtonText: 'Sair',
        })
        .then((result) => {
          if (result.isConfirmed === true) window.location.href = '/'
        })
    },
    resetQuestions() {
      this.currentProgress = 0
      this.currentQuestion = 0
      this.lastQuestion = false
    },
    async sendConsent() {
      try {
        this.$swal.showLoading()
        await this.$store.dispatch('dashboard/sendConsent', this.consentPayload)
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Solicitação enviada com sucesso!',
        })
        this.$bvModal.hide('scr-questions')
        this.$router.push({ name: `dashboard.status` })
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Erro ao tentar enviar o consentimento, tente novamente em instantes!',
        })
      }
    },
  },
  computed: {
    modalSize() {
      return this.lastQuestion ? 'lg' : 'md'
    },
    consentPayload() {
      return {
        link_termo: 'https://credito-frete.com.br/termos-de-uso',
        consentimento: true,
        scr_perguntas: JSON.stringify(this.questions),
        offerKind: this.offerKind,
      }
    },
  },
}
</script>

<style lang="scss">
.modal-footer {
  border-top: none;
}
</style>
