<template>
  <b-card class="mb-0">
    <b-row class="mb-2">
      <b-col cols="12">
        <slot name="header" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="9">
        <div class="d-flex h-100">
          <b-card
            no-body
            border-variant="light"
            class="w-25 p-2 no-right-border-radius mb-0 d-flex justify-content-center align-items-center"
            v-if="showLeftIcon"
          >
            <b-img :src="imgSrc" />
          </b-card>
          <b-card
            v-if="cardBody"
            border-variant="light"
            class="w-100 mb-0"
            :class="{ 'no-left-border-radius': this.showLeftIcon }"
          >
            <slot name="form" />
          </b-card>
          <slot name="form" v-else />
        </div>
      </b-col>
      <b-col cols="3">
        <b-card
          no-body
          class="d-flex flex-column justify-content-center align-items-center h-100 padlocks-bg px-1"
          v-if="showRightIcon"
        >
          <b-img :src="iconSrc" />
          <div
            class="d-flex justify-content-between align-items-center mb-1 p-1 call-whats"
            @click="goToWhats"
          >
            <b-img :src="whats" fluid class="mr-2" />
            <div>
              <p class="mb-0 need-help-text">Precisa de ajuda?</p>
              <small class="need-help-whats">Chame no WhatsApp</small>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BButton, BCard, BRow, BCol, BImg } from 'bootstrap-vue'

export default {
  name: 'ContainerForm',
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BImg,
  },
  props: {
    img: {
      type: String,
      default: 'images/dashboard/discount.svg',
    },
    icon: {
      type: String,
      default: 'images/dashboard/padlocks.svg',
    },
    showLeftIcon: {
      type: Boolean,
      default: true,
    },
    showRightIcon: {
      type: Boolean,
      default: true,
    },
    cardBody: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goToWhats() {
      window.open('https://wa.me/551130343417', '_blank')
    },
  },
  computed: {
    imgSrc() {
      return require(`@/assets/${this.img}`)
    },
    iconSrc() {
      return require(`@/assets/${this.icon}`)
    },
    whats() {
      return require('@/assets/images/dashboard/whatsup.svg')
    },
  },
}
</script>

<style lang="scss">
.no-right-border-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.no-left-border-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.padlocks-bg {
  background-color: #f8fafc;
}

.call-whats {
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #dae1e8;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}
</style>
